<template>
  <!-- same as library -->
  <div>
    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="visibility1"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideDepSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Category"
              invalid-feedback="Category is required."
              ref="CatName"
            >
              <b-form-input
                placeholder="Enter category name"
                name="classField"
                ref="CatName"
                v-model="catObj.category"
                @focusout="CheckCatName()"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              @click="AddCategory()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-modal
      id="modal-login"
      hide-footer
      cancel-variant="outline-secondary"
      ok-title="Show data"
      centered
      no-close-on-backdrop
      title="Filters"
    >
      <b-form>
        <b-form-group
          label="Class"
          invalid-feedback="class is required."
          ref="class"
        >
          <v-select
            v-model="filterObj.classID"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="classes"
            :clearable="false"
            label="name"
            :reduce="(val) => val.id"
            ref="class"
            placeholder="Select class"
          />
        </b-form-group>

        <b-form-group
          label="Subject"
          invalid-feedback="Subject is required"
          ref="subject"
        >
          <v-select
            ref="subject"
            v-model="filterObj.subjectID"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="subjects"
            :reduce="(val) => val.id"
            :clearable="false"
            label="subject"
            placeholder="Select subject"
          />
        </b-form-group>

        <b-form-group
          label="Topic"
          invalid-feedback="Topic is required"
          ref="topic"
        >
          <v-select
            ref="topic"
            v-model="filterObj.topicID"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="topics"
            :reduce="(val) => val.id"
            label="topic"
            :clearable="false"
            placeholder="Select topic"
          />
        </b-form-group>

        <b-form-group
          label="Category"
          invalid-feedback="Category is required"
          ref="filter_cat"
        >
          <v-select
            v-model="filterObj.catID"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="items"
            :reduce="(opt) => opt.id"
            :clearable="false"
            ref="filter_cat"
            label="category"
            placeholder="Select category"
          />
        </b-form-group>
      </b-form>
      <b-row class="mt-2">
        <b-col style="display: flex; justify-content: end">
          <b-button
            @click="LoadGrid()"
            style="padding: 12px 16px"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-primary"
            class="mr-1"
          >
            <span class="align-middle">Clear</span>
          </b-button>
          <b-button
            @click="FilterData()"
            style="padding: 12px 16px"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
          >
            <span class="align-middle">Show data</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-card>
      <b-row align-h="end" class="text-right">
        <b-col class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-1 mt-1" cols="11">
          <!-- :class="{ 'my-collapsing adjust': !showAll }" -->
          <b-dropdown
            style="margin-inline: 2px; margin-bottom: 2px"
            v-for="(d, ind) in items"
            :key="ind"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            split
            :variant="current == d.id ? 'primary' : 'outline-primary'"
            :text="d.category"
            right
          >
            <!-- @click="LoadGrid(d.id)" -->
            <b-dropdown-item @click="EditCat(d)"> Edit </b-dropdown-item>
            <b-dropdown-item @click="DeleteCat(d.id)"> Delete </b-dropdown-item>

            <b-dropdown-divider />
          </b-dropdown>
          <!-- </div> -->
        </b-col>
        <b-col
          class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-1 mt-1"
          style="margin-right: 8px"
          cols="*"
        >
          <b-button
            @click="AddOpen()"
            style=""
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon rounded-circle mr-50"
          >
            <feather-icon size="16" icon="PlusIcon" />
          </b-button>

          <!-- <b-button
              @click="showAll = !showAll"
              style=""
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon rounded-circle"
            >
              <feather-icon
                v-if="!showAll"
                class="cursor-pointer"
                size="20"
                icon="ArrowDownIcon"
              />
              <feather-icon
                v-else
                class="cursor-pointer"
                icon="ArrowUpIcon"
                size="20"
              />
            </b-button> -->
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col xl="2" lg="3" md="4" sm="12" cols="12">
          <b-button
            @click="openQuiz(0)"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Add Quiz</span>
          </b-button>
        </b-col>

        <b-col
          class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
          xl="8"
          lg="6"
          md="4"
          sm="12"
          cols="12"
        >
          <b-form-group class="">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col xl="2" lg="3" md="4" sm="12" cols="12">
          <!-- v-if="!byFilter" -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            @click="openFilter()"
          >
            <feather-icon icon="FilterIcon" class="mr-50" />
            <span class="align-middle">Filter</span>
          </b-button>
          <!-- <b-button
            v-else
            @click="LoadGrid()"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
          >
            <feather-icon icon="XIcon" class="mr-50" />
            <span class="align-middle">Clear</span>
          </b-button> -->
        </b-col>
      </b-row>

      <b-table
        class="mt-1"
        ref="selectableTable"
        :items="filters"
        :fields="fields"
        :busy="dataLoading"
        :current-page="currentPage"
        :per-page="perPage"
        show-empty
        responsive
        hover
        selectable
        select-mode="single"
        @row-selected="openQuiz($event[0].question.id)"
      >
        <template #empty="scope">
          <h3 class="mt-1 mb-1" style="text-align: center">No records found</h3>
        </template>
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template #cell(media)="data">
          <b-img
            v-if="data.item.question.media"
            rounded
            :src="data.item.question.media"
            height="80px"
            width="80px"
            alt="cover"
          />
          <!-- <b-img
            v-else
            src="https://cdn.cloudious.net/file-1677914849290-677244852.png"
            rounded
            height="80px"
            width="80px"
            alt="cover"
          /> -->
        </template>

        <template #cell(title)="data">
          <b-badge variant="light-primary">
            {{ data.item.title }}
          </b-badge>
        </template>

        <template #head(actions)="data">
          <div class="mr-5 text-center">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.top
              title="Edit"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="openQuiz(data.item.question.id)"
            >
              <feather-icon icon="EditIcon" class="" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              v-b-tooltip.hover.top
              title="Delete"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="DeleteQuiz(data.item.question.id)"
            >
              <feather-icon icon="Trash2Icon" class="" />
            </b-button>
          </div>
        </template>
      </b-table>
      <b-col cols="12" class="d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="filters.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { TheMask } from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    BModal,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BMediaAside,
    BImg,
    BMedia,
    BLink,
    BForm,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    TheMask,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    // let right = {};
    // this.$store.state.menu.forEach((el) => {
    //   el.children.forEach((ch) => {
    //     right[ch.route] = {
    //       view: ch.view,
    //       add: ch.add,
    //       edit: ch.edit,
    //       delete: ch.delete,
    //     };
    //   });
    // });
    // // console.log(right);
    // this.$store.commit("setRights", right);
    // if (!this.$store.state.rights[this.$route.name]) {
    //   // console.log(this.$store.state.rights[this.$route.name]);
    //   this.$router.replace({
    //     name: "misc-not-authorized",
    //   });
    // } else {
    //   this.rights = this.$store.state.rights[this.$route.name];
    //   this.LoadGrid();
    //   this.LoadData(0);
    //   this.LoadPub();
    //   this.LoadAuth();
    // }
    this.LoadGrid();
    this.LoadData(0);
    this.loadInfo();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    getColor(ind) {
      console.log("%%%%%% getting color", ind.index);
      return this.color[ind];
    },

    PageChange() {
      console.log(this.currentPage);
      return this.LoadData();
    },
    filters: function () {
      return this.gridData.filter((pro) => {
        return pro.question.question
          .toLowerCase()
          .match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      rights: {},
      showAll: false,
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,
      visibility2: false,
      visibility3: false,

      sidebarTitle: "Add Class",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      coverLoading: false,
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        // { label: "media", key: "media" },
        { label: "question", key: "question.question" },
        // { label: "class", key: "class" },
        // { label: "details", key: "details" },
        { key: "actions", label: "actions" },
      ],
      pubFields: [
        { label: "Publisher", key: "publisher" },
        { key: "actions", label: "actions" },
      ],
      authFields: [
        { label: "Author", key: "author" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      gridData: [],
      subjects: [],
      topics: [],
      selected: null,
      rangeDate: null,
      byFilter: false,
      filterObj: {
        classID: 0,
        subjectID: 0,
        topicID: 0,
        catID: 0,
      },
      myObj: {
        id: 0,
        categoryID: 0,
        authorID: 0,
        authorID: 0,
        publisherID: 0,
        title: "",
        details: "",
        cover: "",
        pdf: "",
        status: "active",
        tag: "",
        campusID: this.$store.state.userData.cId,
      },

      assignedClass: null,
      classes: [],
      publishers: [],
      authors: [],
      showForm: false,
      buttonIcon: "",
      catObj: {
        id: 0,
        category: "",

        campusID: this.$store.state.userData.cId,
      },
      logoloading: false,

      detailObj: {},
      timeout: 10000,
      current: 0,
      mediaLoaded: false,
      uploading: false,
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),
    openFilter() {
      this.$bvModal.show("modal-login");
    },

    openQuiz(id) {
      this.$router.push({
        name: "quiz-question",
        params: { id: id },
      });
    },
    EditBook(item) {
      this.myObj = item;
      this.showForm = true;
      if (this.myObj.cover == null || this.myObj.cover == "") {
        this.coverLoading = false;
      } else this.coverLoading = "loaded";
      if (this.myObj.pdf == null || this.myObj.pdf == "") {
        this.mediaLoaded = false;
      } else this.mediaLoaded = true;
      var elem = this.$refs["desc"];
      elem.state = undefined;
      var elem = this.$refs["title"];
      elem.state = undefined;
      var elem = this.$refs["catId"];
      elem.state = undefined;
      var elem = this.$refs["pubId"];
      elem.state = undefined;
      var elem = this.$refs["authId"];
      elem.state = undefined;
    },
    removePdf() {
      this.myObj.pdf = null;
      this.mediaLoaded = false;
      this.$refs.pdf.value = null;
    },
    uploadPdf() {
      if (this.$refs.pdf.files[0] !== "") {
        this.uploading = true;
        this.mediaLoaded = false;

        let formData = new FormData();
        formData.append("file", this.$refs.pdf.files[0]);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            this.myObj.pdf = fn1;
            // console.log(this.myObj.pdf);
            this.uploading = false;
            this.mediaLoaded = true;
            this.CheckPdf();
          })
          .catch((err) => {
            console.log(err);
            this.uploading = false;
          });
      }
    },
    deleteCover() {
      this.myObj.cover = null;
      this.coverLoading = false;
      this.$refs.coverImg.value = null;
    },
    selectCover() {
      if (this.$refs.coverImg.files[0] !== "") {
        this.coverLoading = true;

        let formData = new FormData();
        formData.append("file", this.$refs.coverImg.files[0]);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            this.myObj.cover = fn1;
            // console.log(this.myObj.cover);
            this.coverLoading = "loaded";
          })
          .catch((err) => {
            console.log(err);
            this.coverLoading = false;
          });
      }
    },

    async DeleteQuiz(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "QuizQuestions/DeleteQuestion?db=" +
            this.$store.state.userData.db +
            "&cId=" +
            this.$store.state.userData.cId +
            "&qID=" +
            id,
          body: null,
          message: "Quiz removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadGrid();
      }
    },

    OpenDepartmentF() {
      this.visibility = true;
      //   this.depObj.id = 0;
      //   this.depObj.name = "";
      this.depObj.campusID = this.$store.state.userData.cId;
      this.sidebarTitle = "Add Fee";
      this.sidebarButton = "Save";
      //   var elem = this.$refs["depname"];
      //   elem.state = undefined;
    },

    AddOpen() {
      this.catObj = {
        id: 0,
        category: "",
        campusID: this.$store.state.userData.cId,
      };
      this.sidebarTitle = "Add Category";
      this.sidebarButton = "Save";
      this.visibility1 = true;
      this.logoloading = false;
      var elem = this.$refs["CatName"];
      elem.state = undefined;
    },
    deleteLogo() {
      this.catObj.icon = null;
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    selectlogo() {
      if (this.$refs.fileProfile.files[0] !== "") {
        this.logoloading = true;

        let formData = new FormData();
        formData.append("file", this.$refs.fileProfile.files[0]);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);

            var fn1 = e.data.myresp[0].path;

            this.catObj.icon = fn1;

            // console.log(this.catObj.icon);
            this.logoloading = "loaded";
            this.CheckIcon();
          })
          .catch((err) => {
            console.log(err);
            this.logoloading = false;
          });
      }
    },

    EditCat(item) {
      this.catObj = { ...item };
      this.sidebarTitle = "Edit Category";
      this.sidebarButton = "Update";
      this.visibility1 = true;

      var elem = this.$refs["CatName"];
      elem.state = undefined;
    },

    async DeleteCat(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "QuizCategory/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Quiz Category removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData(0);
      }
    },

    CheckRequired(name) {
      var elem = this.$refs[name];
      if (this.myObj.name == "" || this.myObj.name > 20) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }

      // return (elem.state = this.$v.depObj.name.required);
    },

    CheckTitle() {
      var elem = this.$refs["title"];
      if (this.myObj.title == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckPdf() {
      var elem = this.$refs["bookPdf"];
      if (this.myObj.pdf == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDesc() {
      var elem = this.$refs["desc"];
      if (this.myObj.details == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCatId() {
      var elem = this.$refs["catId"];
      if (this.myObj.categoryID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckPubId() {
      var elem = this.$refs["pubId"];
      if (this.myObj.publisherID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAuthId() {
      var elem = this.$refs["authId"];
      if (this.myObj.authorID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAmount() {
      var elem = this.$refs["amount"];
      if (this.mydefaultAmount == 0 || this.mydefaultAmount == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCatName() {
      var elem = this.$refs["CatName"];
      if (this.catObj.category.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckIcon() {
      var elem = this.$refs["icon"];
      // console.log(elem);
      if (this.catObj.icon == "" || this.catObj.icon == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckRecurrence() {
      var elem = this.$refs["recurrence"];
      if (this.myObj.recurrence == null || this.myObj.recurrence == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },
    hideDepSideBar() {
      this.visibility1 = false;
    },

    Form() {
      this.$router.push("/apps/schoolinfo");
    },
    checkStatus() {
      if (this.myObj.Status == "") {
        return (this.errors.status = true);
      } else {
        return (this.errors.status = false);
      }
    },
    async loadInfo() {
      var obj2 = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      let result = await this.get(obj2);
      result.forEach((el) => this.classes.push(el.cls));

      var obj = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.subjects = await this.get(obj);

      var obj3 = {
        url:
          this.$store.state.domain +
          "QuizTopic?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.topics = await this.get(obj3);
    },

    async LoadData(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "QuizCategory?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      // console.log("cat", this.items);
      //   if (id == 0) {
      //     let catId = this.items[0].id;
      //     this.LoadGrid(catId);
      //   } else if (id == 1) {
      //     let catId = this.items[this.items.length - 1].id;
      //     this.LoadGrid(catId);
      //   } else {
      //     this.LoadGrid(id);
      //   }
    },
    async LoadGrid(id) {
      // this.current = id;
      this.$bvModal.hide("modal-login");
      this.byFilter = false;
      this.dataLoading = true;
      var status = await this.post({
        url:
          this.$store.state.domain +
          "QuizQuestions/LoadGrid?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        body: {
          classID: 0,
          subjectID: 0,
          topicID: 0,
          catID: 0,
        },
        subjects: true,
        context: this,
        token: this.$store.state.userData.token,
      });
      //   console.log(status);
      if (status) {
        this.gridData = status;
      }

      this.dataLoading = false;
      // console.log("grid", this.gridData);
    },
    async FilterData() {
      this.$bvModal.hide("modal-login");
      this.byFilter = true;
      this.dataLoading = true;
      var status = await this.post({
        url:
          this.$store.state.domain +
          "QuizQuestions/LoadGrid?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        body: this.filterObj,
        subjects: true,
        context: this,
        token: this.$store.state.userData.token,
      });
      //   console.log(status);
      if (status) {
        this.gridData = status;
      }

      this.dataLoading = false;
      // console.log("grid", this.gridData);
    },

    async AddCategory() {
      this.CheckCatName();
      if (this.CheckCatName() == true) {
        if (this.catObj.id == 0) {
          //Add
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "QuizCategory?db=" +
              this.$store.state.userData.db,
            body: this.catObj,
            message: "Quiz Category added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility1 = false;
          if (status) this.LoadData(1);
        } else {
          //Edit
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "QuizCategory/" +
              this.catObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.catObj,
            message: "Quiz Categries updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility1 = false;
          if (status) this.LoadData(this.catObj.id);
        }
      }
    },

    async Add() {
      // console.log("obj:", this.myObj);
      this.CheckTitle();
      this.CheckPdf();
      // this.CheckDesc();
      this.CheckCatId();
      this.CheckAuthId();
      this.CheckPubId();
      if (
        this.CheckTitle() == false ||
        // this.CheckDesc() == false ||
        this.CheckPdf() == false ||
        this.CheckCatId() == false ||
        this.CheckPubId() == false ||
        this.CheckAuthId() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.myObj.defaultAmount = parseInt(this.mydefaultAmount);
        if (this.myObj.id == 0) {
          //Add
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Books?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Book added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.LoadGrid(1);
            // this.showForm = false;
          }
        } else {
          //Edit
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "Books/" +
              this.myObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Book updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.LoadGrid(this.myObj.id);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.my-collapsing {
  height: 55px;
  overflow: hidden;
}
.my-collapsing.adjust {
  height: 39px;
}
.my-buttons {
  position: absolute;
  top: 0px;
  right: 10px;
  cursor: pointer;
}

.lib-form-right {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
}

.lib-form-left {
  width: 20%;
}

@media (max-width: 1100px) {
  .lib-form-right {
    width: 75%;
  }
  .lib-form-left {
    width: 25%;
  }
}
@media (max-width: 992px) {
  .lib-form {
    flex-direction: column;
  }
  .lib-form-right {
    width: 100%;
  }
  .lib-form-left {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
